import {
    db
  } from '@/firebase';
import { en } from '@/lang/en-US.js';
import { es } from '@/lang/es-ES.js';
  
  const store = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async en({dispatch}) {
            let result = {};
            try {
                const res = await db.collection('lang').doc(`en`).get();
                result = res.data();
                if(!result.about || !result) {
                    dispatch('write_en', en);
                    return;
                }
                localStorage.setItem('en', JSON.stringify(result));
            } catch (error) {
                console.log(error);
            }
            return result;
        },
        async es({dispatch}) {
            let result = {};
            try {
                const res = await db.collection('lang').doc(`es`).get();
                result = res.data();
                if(!result.about || !result) {
                    dispatch('write_es', es);
                    return;
                }
                localStorage.setItem('es', JSON.stringify(result));
            } catch (error) {
                console.log(error);
            }
            return result;
        },
        async write_en({}, data) {
            try {
                await db.collection('lang').doc(`en`).set(data);
            } catch (error) {
                console.log(error);
            }
        },
        async write_es({}, data) {
            try {
                await db.collection('lang').doc(`es`).set(data);
            } catch (error) {
                console.log('error');
            }
        },
    }
  }
  export default store;