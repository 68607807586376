import { p_es } from "./privacyText";
let esY = null
try {
  esY = JSON.parse(localStorage.getItem('es'));
} catch (error) {
  console.error(error)
}
let esX = {
  version: '1.0',
  navitationMenu: {
    home: "Inicio",
    aboutUs: "Nosotros",
    services: "Servicios",
    coverage: "Cobertura",
    apply: 'Aplicar cambios',
    contactUs: "Contactanos",
    contactUsQuote: "Cotización",
    contactUsCarrier: "Grobe Carrier",
  },
  home: {
    seo: {
      title: "Inicio",
      description: "Servicios Logísticos en México y Estados  Unidos",
      keywords:
        "Servicios Logísticos, Servicios Logísticos en México, Servicios Logísticos en Estados  Unidos",
    },
    title: "Servicios Logísticos en México & Estados  Unidos",
    titleSubText: "Te ayudamos a hacerlo posible",
    singleTitle: '',
    servicesSection: {
      title: "¡Queremos ser parte de tu cadena de distribución y suministro!",
      storage: "Almacenaje",
      ltransport: "Transporte Terrestre",
      taDistributiont: "Transporte & Distribución",
      iae: "Importación & Exportación",
      view: "ver servicio",
    },
    wewanttobetitle: '',
    wewanttobelist: [
      {
        id: 'h_storage',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133075/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Storage_438x155_2_xchv1a.png',
        title: '',
        text: '',
        button: 'ver servicio',
      },
      {
        id: 'h_land_t',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133075/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Land_Transport_438x155_eewl4s.png',
        title: '',
        text: '',
        button: 'ver servicio',
      },
      {
        id: 'h_cross',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133074/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__importexport_438x155_npgduh.png',
        title: '',
        text: '',
        button: 'ver servicio',
      },
      {
        id: 'h_transit',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133076/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Transporand_distrib_438x155_ohfrlw.png',
        title: '',
        text: '',
        button: 'ver servicio',
      },
    ],
    aboutus: '',
    reasonswhy: {
      title: "¡Razones por las cuales somos tu mejor opción!",
      textOne:
        "Soluciones a la medida: Contamos con experiencia y flexibilidad para satisfacer cualquier tipo de proyecto.",
      textTwo:
        "Trazabilidad: Monitoreamos constantemente su mercancía para que usted sepa en todo momento dónde se encuentra su carga.",
      textThree:
        "Tarifas competitivas: Manteniendo en todo momento la calidad de nuestros servicios.",
      textFour:
        "Seguridad garantizada: Asegurándonos que nuestros aliados estratégicos cumplan con los permisos necesarios para poder ser parte de nuestra operación y garantizar la seguridad de su carga. ",
    },
    coverage: "Cobertura",
    coverage_details:
      "¡Llevamos a tu empresa más lejos! Explora nuestra cobertura",
    coverage_btn: "Conoce más",
    contact: "¡Descubre cómo podemos ayudarte!",
    contact_details: "Contamos con diferentes soluciones logísticas. ",
    contact_btn: "Conoce  más",
    certified: "Transporte certificado y seguro",
    certified_details:
      "Nuestras alianzas con los mejores transportistas experimentados nos permite garantizar, que las cargas de nuestros clientes  se entregarán de forma rápida y segura.",
    certified_details_two: "",
    certified_btn: "Conce más",
    section_three: "Garantizamos la seguridad de tu mercancía",
    st_details:
      "Nos aseguramos que nuestros aliados estratégicos cumplan con las certificaciones y permisos necesarios para poder ser parte de nuestra operación y garantizar un excelente servicio a nuestros clientes.",
  },
  about: {
    seo: {
      title: "Sobre Grobe",
      description:
        "Acerca de Grobe (Servicios Logísticos en México y Estados  Unidos)",
      keywords:
        "Servicios Logísticos, Servicios Logísticos en México, Servicios Logísticos en Estados  Unidos",
    },
    title: "<b>Eficiencia</b> en nuestro proceso.<br><b>Siempre.</b>",
    section_one: "¿Quienes somos?",
    so_card_about: [
      {
        titulo: "Nuestra Empresa",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133045/Img%20Omni%20WEB/Pag%20ABOUT%20US-%20Nosotros/Seccion%20About%20Us/AboutUs_Ourcompany_185x189_utzlte.png",
        detalle:
          "Desde el año 2019, iniciamos operaciones en Laredo Texas. <br/>Día con día nos hemos posicionado como una de las mejores prestadoras de servicios de logística en la frontera de México con Estados Unidos.",
      },
      {
        titulo: "Nuestros valores más imprtantes",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133044/Img%20Omni%20WEB/Pag%20ABOUT%20US-%20Nosotros/Seccion%20About%20Us/AboutUs_Most_important_185x189_phzece.png",
        detalle:
          "Es importante para el equipo Grobe, siempre tener presente los valores de: confianza, innovación, experiencia y la seguridad.",
      },
      {
        titulo: "Visión",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642129820/Omni/AboutUs/ABOUT_US_medium-shot-woman-wearing-face-mask_uicbi4.jpg",
        detalle:
          "Crear la red de transporte más eficiente de América del Norte. Continuamente buscamos nuevas formas en que nuestra gente, procesos y tecnología puedan usarse para hacer avanzar el transporte de carga.",
      },
      {
        titulo: "Nuestra Misión",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133045/Img%20Omni%20WEB/Pag%20ABOUT%20US-%20Nosotros/Seccion%20About%20Us/AboutUs_Ourmission_185x189_pd6zhf.png",
        detalle:
          "Crear la red de transporte más eficiente de América del Norte. Continuamente buscamos nuevas formas en que nuestra gente, procesos y tecnología puedan usarse para hacer avanzar el transporte de carga.",
      },
    ],
    headquarters: "Centro de Operaciones Grobe",
    h_details:
      "Con oficinas en Estados Unidos y México, trabajamos para brindarle soluciones cerca de usted.",
    h_unites:
      "<b>Estados Unidos</b> <br/> Laredo Texas <br/> Fresno California",
    h_mex: "<b>México</b> <br/> Nuevo Laredo Tamaulipas<br/>Monterrey, Nuevo León",
  },
  services: {
    seo: {
      title: "Nuestros servicios",
      description: "Nuestro servicio a la medida de tus necesidades",
      keywords:
        "Servicios Logísticos, Servicios Logísticos en México, Servicios Logísticos en Estados  Unidos",
    },
    title: "<b>Nuestros servicios</b> se adaptan a <b>tus necesidades.</b>",
    section_one: "Servicios",
    so_subtitle:
      "¡Queremos ser parte de tu cadena de distribución y suministro!",
    so_details:
      "Nuestro conocimiento del mercado y experiencia, aunado a las alianzas y relaciones comerciales que hemos forjado con compañías de transporte, nos permiten obtener costos competitivos y mejores condiciones de servicio.",
    so_crads: [
      {
        id: "storage",
        view: 'SOLICITAR COTIZACIÓN DE ALMACENAJE',
        titulo: "Almacén y Distribución",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133075/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Storage_438x155_2_xchv1a.png",
        detalle:
          "Contamos con espacios en Laredo Texas, diseñados para almacenar su mercancía de forma confiable y segura.",
      },
      {
        id: "land",
        view: 'SOLICITAR COTIZACIÓN DE TRANSPORTE',
        titulo: "Transporte y Gestión",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133075/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Land_Transport_438x155_eewl4s.png",
        detalle:
          "Envíos de mercancías de transporte de carga general a nivel nacional e internacional. Con una extensa red de transportistas asociados para que su mercancía llegue a su destino.",
      },
      // {
      //   id: "iae",
      //   view: 'SOLICITAR COTIZACIÓN DE TRANSPORTE',
      //   titulo: "Importación & Exportación",
      //   img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133074/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__importexport_438x155_npgduh.png",
      //   detalle:
      //     "Le ofrecemos servicios integrales en los que se incluyen todas aquellas actividades implicadas en la gestión de operación en la importación y exportación de mercancías por las aduanas de cada país de origen o destino.",
      // },
      // {
      //   id: "transport",
      //   view: 'Solicite hablar con un representante del cliente',
      //   titulo: "Transporte & Distribución",
      //   img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133076/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Transporand_distrib_438x155_ohfrlw.png",
      //   detalle:
      //     "Servicio de puerta a puerta, tanto de importación como de exportación entre México y EU.",
      // },
    ],
    formulario: {
      auto: [
        {
          name: "Nombre",
          id: "Name",
          required: true,
          type: "text",
        },
        {
          name: "Compañia",
          id: "Company",
          required: false,
          type: "text",
        },
        {
          name: "Dirección de correo electrónico",
          id: "Email",
          required: false,
          email: true,
          type: "email",
        },
        {
          name: "Número de télefono",
          id: "Number",
          required: false,
          Number: true,
          type: "number",
        },
        {
          name: "Código postal de origen",
          id: "Origin_zip",
          required: false,
          Number: true,
          type: "number",
        },
        {
          name: "Código postal destino",
          id: "Destination_zip",
          required: false,
          Number: true,
          type: "number",
        },
        {
          name: "Recuento de paletas",
          id: "Pallete_clount",
          required: false,
          type: "text",
        },
        {
          name: "Peso del envío",
          id: "Shipment_weight",
          required: false,
          type: "txet",
        },
      ],
      manual: {
        ship_date: {
          name: "Fecha de envío",
          id: "Ship_date",
        },
        delivery_date: {
          name: "Fecha de entrega",
          id: "Delivery_date",
        },
      },
    },
    industry: "Industria",
    i_subtitle:
      "¿Tiene mercancía que necesita trasladar? ¡Somos su mejor opción! Abarcamos los siguientes sectores:",
    i_cards: [
      {
        id: "auto",
        titulo: "Automotriz",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133072/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/industry/Service__Industry_Autoparts_384x133_qfhhtn.png",
        detalle:
          "Transportamos toda clase de piezas automotrices de vehículos de forma nacional e internacional.",
      },
      {
        id: "alimentos",
        titulo: "Alimentos",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133073/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/industry/Service__Industry_food_384x133_fja7i3.png",
        detalle:
          "Desarrollamos soluciones de almacenamiento y transporte de productos congelados (perecederos y refrigerados) sensibles a la temperatura y procesados.",
      },
      {
        id: "farma",
        titulo: "Farmacéutico",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133073/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/industry/Service__Industry_Pharma_384x133_lelqme.png",
        detalle:
          "Contamos con una amplia red de transportistas y servicios logísticos adecuados para el  traslado de suministros farmacéuticos, sanitarios, cosméticos y médicos de manera segura.",
      },
      {
        id: "beers",
        titulo: "Bebidas",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133073/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/industry/Service__Industry_Beverages_384x133_pmtmgv.png",
        detalle: "Alcohólicas y no alcohólicas",
      },
      {
        id: "Hygienic",
        titulo: "Productos Higiénicos",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1683520624/Omni/Services/toalla-doblada-productos-cosmeticos-cepillos-dientes-sobre-fondo-naranja1_kpevye.jpg",
        detalle: "El transporte de tus productos con calidad y seguridad",
      },
      {
        id: "GeneralGoods",
        titulo: "Mercancía General",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1683520407/Omni/Services/aaron-doucett-liOAS02GnfY-unsplash_ba61sa.jpg",
        detalle: "Brindamos la mejor experiencia en logística y servicios especializados con el mejor equipo.",
      },
      {
        id: "RecycledE",
        titulo: "Reciclaje de aparatos electrónicos",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1683520406/Omni/Services/robin-glauser-zP7X_B86xOg-unsplash_ss1vz6.jpg",
        detalle: "Nuestro equipo de expertos mejoran la cadena de valor del producto, optimizar el proceso logístico y explorar nuevos mercados",
      },
      {
        id: "RecycledP",
        titulo: "Papel reciclado",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1683520407/Omni/Services/disposicion-objetos-sucios-arrojados_fr0x4z.jpg",
        detalle: "Adaptamos soluciones para ofrecer valor y proporcionar servicios integrados y transporte eficiente. ",
      },
      {
        id: "HeavyEquipament",
        titulo: "Maquinaria pesada",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1683521061/Omni/Services/foto-linea-produccion-automoviles-soldadura-carrocerias-automoviles-planta-ensamblaje-automoviles-moderna-industria-automotriz6_odwvc5.jpg",
        detalle: "Brindamos excelencia que establece el estándar para hacer que la experiencia de fabricació de nuestros clientes sea la mejor posible.",
      }
    ],
    tech: "Tecnología",
    t_subtitle:
      "Las  tecnologías  de soporte logístico y nuestro equipo de desarrollo de sistemas interno nos  ayudan a operar de manera eficiente y son la clave de nuestro éxito de operación logística.",
    t_one: "Tecnología líder en la industria",
    t_two: "Gestión de Inventario",
    t_three: "Seguimiento en tiempo real y de cobertura 24/7",
    t_four:
      "Utilizamos programas de rastreo como por ejemplo: Four Kites, Macro Point, entre otros",
    t_five: "Sistema de gestión de almacenes",
    contact: "¡Descubre cómo podemos ayudarte!",
    c_subtitle: "Contamos con diferentes soluciones logísticas. ",
    c_btn: "Conoce más",
    storage: {
      title_image: '<b>Almacén y Distribución<b> <br> <p>Estamos comprometidos a brindar estándares logísticos eficientes<p>',
      title: 'En Grobe, estamos comprometidos a brindar estándares logísticos eficientes que ayuden a organizar, administrar y llevar a cabo un entorno seguro para sus necesidades de productos y servicios',
      subtitle: 'Estamos equipados con la última tecnología para el procesamiento de pedidos, gestión de almacenes, embalaje, mezcla de productos y cumplimiento de pedidos. - Servicios de crossdocking - Logística administrada - Instalaciones de almacenamiento en Laredo, Texas 30,000 pies cuadrados disponibles - Instalaciones de almacenamiento en Fresno, California 30,000 pies cuadrados disponibles - Carga parcial (LTL) - Servicio acelerado - Servicios logísticos internacionales en Monterrey, México y Nuevo Laredo, TM.',
      button: 'Más  Información',
      contact: 'Contacto'
    },
    land: {
      title_image: '<b>Transporte y Gestión<b> <br> <p>Soluciones personalizadas que son únicas para tu negocio<p>',
      title: 'La variedad de servicios logísticos de Grobe ofrece soluciones personalizadas que son únicas para su negocio y agiliza su red de proveedores, que incluye a clientes en todo Estados Unidos y México.',
      subtitle: 'Con nuestra base de datos de más de 2000 transportistas asociados a nivel internacional y nacional, podemos proporcionar la capacidad necesaria para las necesidades de su negocio. Incluye atención al cliente 24/7. - Dedicado a clientes con contrato - Temperatura controlada - Flota propiedad de la empresa - Cama plana - Menos de un camión completo (LTL) Local o a unas 300 millas de nuestras instalaciones en Laredo, TX o Fresno, CA -Servicios acelerados (solo conductor del equipo) en los 48 estados',
      button: 'Más  Información',
      contact: 'Contacto'
    }
  },
  coverage: {
    seo: {
      title: "Cobertura",
      description: "Nosotras llevamos tu empresa mas lejos",
      keywords:
        "Servicios Logísticos, Servicios Logísticos en México, Servicios Logísticos en Estados  Unidos",
    },
    title: "Llevamos a <b>tu empresa, más lejos.</b>",
    title_mobil: "Llevamos a <b>tu empresa, más lejos.</b>",
    coverage: "Cobertura",
    c_details: "Llevamos a tu compañía más lejos. Explora nuestra cobertura.",
    mex: "México Puntos Logísticos:",
    c_mex: [
      "Nuevo León",
      "Jalisco",
      "Coahuila",
      "Michoacán",
      "Zacatecas",
      "Puebla",
      "San Luis Potosí",
      "Estado de México",
      "Querétaro",
      "CDMX",
      "Guanajuato",
      "Hidalgo",
      'Chihuahua',
      'Sonora'
    ],
    usa: "Todo Estados Unidos",
    c_usa: ["(Excepto Alaska y Hawaii)"],
  },
  contact: {
    seo: {
      title: "Contactanos",
      description: "Agendemos una llamada y crezcamos juntos",
      keywords:
        "Servicios Logísticos, Servicios Logísticos en México, Servicios Logísticos en Estados  Unidos",
    },
    title: "Agenda una llamada con nosotros <b>¡Contáctenos!</b>",
    section_one: "¿Necesita cotizar un servicio?",
    so_details:
      "Envíenos su información y en breve nos comunicaremos con usted.<br><br>Nuestro equipo está listo para ayudarlo. Déjenos un mensaje detallado sobre sus requisitos y estaremos encantados de presentarle soluciones a su problema.",
    ff_name: "Nombre",
    ff_name_company: "Empresa",
    ff_email: "E-mail",
    ff_phone: "Teléfono",
    ff_equip: "Equipo Requerido",
    ff_source: "Origen",
    ff_destination: "Destino",
    ff_comments: "Mensaje",
    ff_privacy: "He leído y acepto el ",
    ff_privacy_link: "Aviso de Privacidad",
    ff_btn: "Enviar",
    section_two: "¿Está interesado en ser parte de nuestros transportistas?",
    st_details:
      "Queremos formar una asociación sólida y permanente con personas apasionadas por la industria del transporte y la logística, conviértase en un  Transportista Grobe  y tenga éxito en sus propios términos.",
    sf_name: "Nombre",
    sf_phone: "Teléfono",
    sf_email: "Email",
    sf_company: "Empresa",
    sf_message: "Mensaje",
    sf_privacy: "He leído y acepto el ",
    sf_privacy_link: "Aviso de Privacidad",
    ff_btn: "Enviar",
    e_title: "Email enviado",
    e_subtitle: "¡Gracias!",
    e_ok: "Aceptar",
  },
  fotter: {
    details:
      "Grobe International Logistics es más que una agencia de logística, somos su solución completa de comercio transfronterizo. Conozca de dónde venimos y hacia dónde nos dirigimos.",
    contact: "Contacto",
    locations: "Oficinas",
    copyright: "© 2022 All rights reserved",
    credits: "Creditos",
    privacy: "Aviso de privacidad",
    direccion_one: 'Paseo Colón 3607 Colonia Jardín, Nuevo Laredo Tamaulipas C.P. 88260',
    direccion_two: '14203 Distribution Avenue, Laredo, TX 78045, Unites States',
  },
  credits: {
    title: "Créditos de las imágenes",
  },
  privacy: {
    title: "Aviso de privacidad",
    text: p_es,
  },
  cookies: {
    title: "Tu privacidad es<br>importante para nosotros.",
    description: `Este sitio web utiliza cookies para brindar su correcta funcionalidad, así como para obtener datos estadísticos para poder brindar un mejor servicio a nuestros clientes.
    <br>Puedes configurarlas en la seccion de la parte inferior, y al finalizar, pulsando el boton "Confirmar preferencia de cookies". También puedes aceptarlas todas pulsando
    “Aceptar todas las cookies”. Para más información, lee nuestra Política
    de privacidad.`,
    btn_one: "ACEPTAR TODAS LAS COOKIES",
    option_one: "Cookies estrictamente necesarias.",
    message: "(Siempre activo)",
    oo_description:
      "Estas cookies son estrictamente necesarias para el correcto funcionamiento del sitio web y no se pueden desactivar.",
    option_two: "Cookies estadística.",
    od_description:
      "Estas cookies se utilizan para poder analizar datos estadísticos y mejorar la calidad de nuestros servicios, así como la experiencia en el sitio web.",
    btn_two: "CONFIRMAR PREFERENCIA DE COOKIES",
  },
  login: {
    title: 'Iniciar sesión',
    email: 'Email',
    pass: 'Contraseña',
    close: 'Cerrar sesión',
    required: 'Este es un campo obligatorio',
    format: 'No cumple con el formato requerido',
  },
  editor: {
    title: 'Editor de contenido',
    subtitle: 'Ingrese el nuevo texto para el campo seleccionado, este reemplazará el campo que está actualmente registrado.',
    original: 'Texto original',
    new: 'Nuevo texto',
    apply: 'Aplicar local',
    load: 'Cargar original',
    cancel: 'Cancelar',
  },
  confirm: {
    title: '¿Desea subir tus cambios?',
    subtitle: 'Esta operación es irreversible.',
    text: '',
    apply: 'Aplicar cambios',
    cancel: 'Cancelar',
  }
};
let es = !!esY ? esY : esX;
function ActualizarES(data, nuevo) {
  if(!nuevo){
    return;
  }
  /*detailsEdit: {
    lang: '',
    page: '',
    element: '',
    subElement: '',
    isArray: '',
    numArray: '',
    objArray: '',
    textOriginal: '',
  }*/
  if(data.isArray){
    if(data.numArray  >= 0) {
      if(data.objArray) {
        if(data.page && data.element){
          if(data.subElement){
            es[data.page][data.element][data.subElement][data.numArray][data.objArray] = nuevo;
          } else {
            es[data.page][data.element][data.numArray][data.objArray] = nuevo;
          }
        }
      } else {
        if(data.page && data.element){
          if(data.subElement){
            es[data.page][data.element][data.subElement][data.numArray] = nuevo;
          } else {
            es[data.page][data.element][data.numArray] = nuevo;
          }
        }
      }
      
    }
  }
  if(data.page && data.element && !data.isArray){
      if(data.subElement){
        es[data.page][data.element][data.subElement] = nuevo;
      } else {
        es[data.page][data.element] = nuevo;
      }
  }

  return;
}

export {
  es,
  ActualizarES
}
