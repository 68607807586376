import {
    auth,
    db
  } from '@/firebase';
  
  const store = {
    namespaced: true,
    state: {
      activaEdit: false,
    },
    getters: {
      getActive(state) {
        return state.activaEdit;
      }
    },
    mutations: {
      editarActivaEdit(state, data) {
        state.activaEdit = data;
      }
    },
    actions: {
      async Login({
        dispatch
      }, key) {
        let respuesta = {
          success: false,
          error: false,
        };
        try {
          await auth.signInWithEmailAndPassword(key.correo, key.pass);
          respuesta.success = auth.currentUser;
          // dispatch("falsoInicio");
        } catch (error) {
          console.log(error)
          switch (error.code) {
            case 'auth/user-not-found':
            case 'auth/wrong-password':
              respuesta.error = 'Revisa tu correo y contraseña.';
              //this.mostrarAdvertencia('Usuario no válido. Revisa tu correo y contraseña.')
              break
            case 'auth/too-many-requests':
              respuesta.error = 'El acceso a esta cuenta ha sido cancelado temporalmente.';
              break
  
            default:
              respuesta.error = 'Ocurrió un error validando la información.';
              //this.mostrarError('Ocurrió un error validando la información.')
              break
          }
        } finally {
          return respuesta;
        }
      },
      cerrarSesion({
        commit
      }) {
        auth.signOut();
      },
      async validar({commit}){
          let result = false;
          let user = auth.currentUser;
            if (user) {
              result = true;
            }
            else {
              result = false;
            }
          commit("editarActivaEdit", result);
          return result;
      }
    }
  }
  export default store;