import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

const DefaultContainer = () => import('@/components/DefaultContainer.vue');
const Home = () => import('@/views/index.vue');
const About = () => import('@/views/about-us.vue');
const services = () => import('@/views/services.vue');
const coverage = () => import('@/views/coverage.vue');
const contact = () => import('@/views/contact-us.vue');
const privacy = () => import('@/views/privacy.vue');
const login = () => import('@/views/login.vue');
const sad = () => import('@/views/almacen-dist.vue');
const tam = () => import('@/views/transporte-ges.vue');

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    component: DefaultContainer,
    redirect: '/home',
    children: [
      {
        path: '/',
        name: 'home',
        component: Home,
      },
      {
        path: '/about',
        name: 'about-us',
        component: About,
      },
      {
        path: '/services',
        name: 'serviceshome',
        redirect: '/services/',
        component: {
          render(c) {
            return c("router-view");
          }},
        children: [
          {
            path: '/',
            name: 'services',
            component: services
          },
          {
            path: 'storage',
            name: 'storage',
            component: sad
          },
          {
            path: 'land',
            name: 'land',
            component: tam
          }
        ]
      },
      {
        path: '/coverage',
        name: 'coverage',
        component: coverage,
      },
      {
        path: '/contact-us',
        name: 'contact-us',
        component: contact,
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: privacy,
      },
      {
        path: "/login-admin",
        name: 'login',
        component: login,
      },
      {
        path: "*",
        redirect: '/home',
      }
    ],
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
