export default async function (element, isArray = false, numArray = -1, objArray = '') {
    const logeado = await this.$store.dispatch("login/validar", '');
    if((isArray && numArray < 0) || !logeado) {
        return;
    }
    const longitud = element.length;
    const apartados = [
        'page',
        'element',
        'subElement',
    ];
    let Actual = 0;
    let detailsEdit = {
        lang: this.$i18n.locale,
        page: '',
        element: '',
        subElement: '',
        isArray,
        numArray,
        objArray,
        textOriginal: element,
    }
    let contenido = '';
    for (let i = 0; i <= longitud; i++) {
        let letra = element.charAt(i);
        if (letra === '.' || (longitud - i) === 0) {
            detailsEdit[apartados[Actual]] = contenido;
            Actual++;
            contenido = '';
        } else {
            contenido += letra;
        }
    }
    this.$store.dispatch("chargeEditData", detailsEdit);
}