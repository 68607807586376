import { p_en } from './privacyText';
let enY = null
try {
  enY = JSON.parse(localStorage.getItem('en'));
} catch (error) {
  console.error(error)
}
let enX = {
  version: '1.0',
  navitationMenu: {
    home: 'home',
    aboutUs: 'about us',
    services: 'services',
    coverage: 'coverage',
    apply: 'Apply changes',
    contactUs: 'contact us',
    contactUsQuote: 'Quote',
    contactUsCarrier: 'Grobe Carriers',
  },
  home: {
    seo: {
      title: 'Home',
      description: 'Logistics services in México and United States',
      keywords: 'Logistics, services, México, United States, LandTransport',
    },
    title: 'Logistics services in México and United States',
    titleSubText: 'We help you make it possible',
    singleTitle: 'Full service transportation provider for domestic and International freight',
    servicesSection: {
      title:
        'We want to be part of your supply and distribution chain!',
      storage: 'Storage',
      ltransport: 'Land Transport',
      taDistributiont: 'Transport & Distributiont',
      iae: 'Import & Export',
      view: 'view service',
    },
    wewanttobetitle: 'We want to be part of your supply and distribution chain!',
    wewanttobelist: [
      {
        id: 'h_storage',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133075/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Storage_438x155_2_xchv1a.png',
        title: 'Storage',
        text: 'Warehousing and Distribution',
        button: 'view service',
      },
      {
        id: 'h_land_t',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133075/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Land_Transport_438x155_eewl4s.png',
        title: 'Land transportation',
        text: 'Full truck load (FTL), LTL small parcel, Local and state deliveries (Texas and California), Door to Door service International USA/MEX/CAN',
        button: 'view service',
      },
      {
        id: 'h_cross',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133074/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__importexport_438x155_npgduh.png',
        title: 'Transportation and Distribution',
        text: 'Crossdocking Service',
        button: 'view service',
      },
      {
        id: 'h_transit',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133076/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Transporand_distrib_438x155_ohfrlw.png',
        title: 'Import and export',
        text: ' Grobe Transit Lines dedicated fleet',
        button: 'view service',
      },
    ],
    aboutus: 'Our employees are passionate about providing excellent service and share a willingness to go the extra mile. We view ourselves as extensions of our customers’ businesses and we treat those same customers the way we want to be treated',
    reasonswhy: {
      title: 'Reasons why we are your best option!',
      textOne:
        'Solutions up to scale: We offer experience and flexibility to satisfy any type of project',
      textTwo:
        'Track & Trace: Constant monitoring of merchandise visualizing load’s location at all times',
      textThree:
        'Competitive rates: Maintaining quality in our services every time',
      textFour:
        'Guaranteed safety: Making sure our strategic partners comply with our necesary permits in order to be part of our operation and guarantee the safety of your load',
    },
    coverage: 'Coverage',
    coverage_details: 'Expand your company’s horizons!',
    coverage_btn: 'More info',
    contact: 'Contact us:',
    contact_details: 'Find out how we can help out and bring solutions to all your logistics needs.',
    contact_btn: 'More Info',
    certified: 'Certified & Safe Transport',
    certified_details: 'We certify only the best carrier in the business to transport our clients loads in a fast & safe way.',
    certified_details_two: 'Want to be an Grobe Carrier?',
    certified_btn: 'More Info',
    section_three: 'We guarantee the safety of your loads by working with today’s standard carrier certifications.',
    st_details: 'We make sure our strategic partners comply with necessary certifications and permits in order to be a part of our operation and offer excellent service. ',
  },
  about: {
    seo: {
      title: 'About Grobe',
      description:
        'About Grobe (Logistic Services in Mexico and the United States)',
      keywords:
        'Logistics Services, Logistics Services in Mexico, Logistics Services in the United States',
    },
    title: '<b>Efficiency in our process.</b><br><b>Always.</b>',
    section_one: 'Who we are?',
    so_card_about: [
      {
        titulo: 'Our Company',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133045/Img%20Omni%20WEB/Pag%20ABOUT%20US-%20Nosotros/Seccion%20About%20Us/AboutUs_Ourcompany_185x189_utzlte.png',
        detalle: 'Founded in 2019, our logistics experiences have taught us many core foundations that include: People you trust. Technology that helps our customers. Capacity to solve solutions and on time.',
      },
      {
        titulo: 'Our most important values',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133044/Img%20Omni%20WEB/Pag%20ABOUT%20US-%20Nosotros/Seccion%20About%20Us/AboutUs_Most_important_185x189_phzece.png',
        detalle: 'Grobe´s team embody our core values of trust, innovation, expertise and safety.',
      },
      {
        titulo: 'Our vision',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642129820/Omni/AboutUs/ABOUT_US_medium-shot-woman-wearing-face-mask_uicbi4.jpg',
        detalle: 'At Grobe, our vision is to bring compelling value through outsourcing.',
      },
      {
        titulo: 'Our mission',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133045/Img%20Omni%20WEB/Pag%20ABOUT%20US-%20Nosotros/Seccion%20About%20Us/AboutUs_Ourmission_185x189_pd6zhf.png',
        detalle: 'Our mission statement says it all: Creating the most efficient transportation network in North America. We continuously look for new ways that our people, process and technology can be used to move freight transportation forward.',
      },
    ],
    headquarters: 'Grobe Headquarters',
    h_details: 'With offices across the United States and Mexico, we will work with you to find solutions in your region. ',
    h_unites: '<b>Unites States</b> <br/> Laredo Texas <br/> Fresno California',
    h_mex: '<b>México</b> <br/> Nuevo Laredo Tamaulipas<br/>Monterrey, Nuevo León',
  },
  services: {
    seo: {
      title: 'Services',
      description: 'Our service tailored to your needs',
      keywords:
        'Logistics Services, Logistics Services in Mexico, Logistics Services in the United States',
    },
    title: '<b>Our service tailored to your needs</b>',
    section_one: 'Services',
    so_subtitle: 'We want to be part of your supply and distribution chain!',
    so_details: 'Gorbe International Logistics provides a variety of freight transportation services to customers throughout the United States, Canada and Mexico.',
    so_crads: [
      {
        id: 'storage',
        view: 'REQUEST WAREHOUSING QUOTE',
        titulo: 'Warehouse & Distribution',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133075/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Storage_438x155_2_xchv1a.png',
        detalle:
          'At Grobe International, we offer a variety of warehousing options that includes storage, care of your merchandise with a warehouse management system that helps in providing great visibility and management options. On top of that, we offer great rates that can help you achieve the most for your business. Our added services include safety camaras rolling 24/7 and inventory planning.',
      },
      {
        id: 'land',
        view: 'REQUEST FREIGHT QUOTE',
        titulo: 'Full truck load (FTL), LTL small parcel, Local and state deliveries (Texas and California)',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133075/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Land_Transport_438x155_eewl4s.png',
        detalle:
          'Whether it’s FTL, LTL, National or local/State deliveries we have you covered! At Grobe International Logistics, we are dedicated in providing you the most cost-effective truck load carrier that can help provide safe and on time delivery always. Our database is filled with trusted partner carriers, from FTL to LTL we have them all. If you’re looking to getting something delivered locally in the states of Texas or California you can also count on us to deliver for you.',
      },
      {
        id: 'door_to_door',
        view: 'REQUEST FREIGHT QUOTE', 
        titulo: 'Door to Door service International USA/MEX/CAN',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133074/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__importexport_438x155_npgduh.png', // Pendiente
        detalle:
          'We offer exclusive dedicated D2D service straight from the suppliers form Mexico to USA or Canada. With over 30 partner carriers in Mexico, we manage to cover all the states of Mexico with safe transit in all of Mexico. All of our partners consist of CTPAT/FAST certifications that can bring assurance to our customers needs. Our cross border points are in Laredo, TX Eagle Pass, TX Tijuana, CA and El Paso, TX. Give us a call to become your dedicated partner!',
      },
      {
        id: 'grobe_transit',
        view: 'Request to speak with a representative',
        titulo: 'Grobe Transit Lines dedicated fleet',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133076/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/Service/SERVICE__Transporand_distrib_438x155_ohfrlw.png',  // Pendiente
        detalle:
          'Grobe International has opened a dedicated fleet of semi trucks ready to assist you for all your needs. These units are owned entirely by Grobe and is a dedicated service provider for you.',
      },
    ],
    formulario: {
      auto: [
        {
          name: "Name",
          id: "Name",
          required: true,
          type: "text",
        },
        {
          name: "Company",
          id: "Company",
          required: false,
          type: "text",
        },
        {
          name: "Email Adress",
          id: "Email",
          required: false,
          email: true,
          type: "email",
        },
        {
          name: "Phone Number",
          id: "Number",
          required: false,
          Number: true,
          type: "number",
        },
        {
          name: "Origin Zip",
          id: "Origin_zip",
          required: false,
          Number: true,
          type: "number",
        },
        {
          name: "Destination Zip",
          id: "Destination_zip",
          required: false,
          Number: true,
          type: "number",
        },
        {
          name: "Pallette Count",
          id: "Pallete_clount",
          required: false,
          type: "text",
        },
        {
          name: "Shipment Weight",
          id: "Shipment_weight",
          required: false,
          type: "txet",
        },
      ],
      manual: {
        ship_date: {
          name: "Ship Date",
          id: "Ship_date",
        },
        delivery_date: {
          name: "Delivery Date",
          id: "Delivery_date",
        },
      },
    },
    industry: 'Industry',
    i_subtitle: 'Any goods you need to transport? We got you covered! <br>We cover the following commodities.',
    i_cards: [
      {
        id: 'auto',
        titulo: 'Autoparts',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133072/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/industry/Service__Industry_Autoparts_384x133_qfhhtn.png',
        detalle:
          'We cover all kinds of autoparts both nationally and internationally.',
      },
      {
        id: 'alimentos',
        titulo: 'Food',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133073/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/industry/Service__Industry_food_384x133_fja7i3.png',
        detalle:
          'We develop solutions for storage and transport of frozen products (perishable and refrigerated) temperature controlled and processed.',
      },
      {
        id: 'farma',
        titulo: 'Pharmaceutical',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133073/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/industry/Service__Industry_Pharma_384x133_lelqme.png',
        detalle:
          'We offer a wide array of carriers and logistic services viable for delivering pharmaceutical equipment, sanitary, cosmetic and medical safely.',
      },
      {
        id: 'beers',
        titulo: 'Beverages',
        img: 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133073/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/industry/Service__Industry_Beverages_384x133_pmtmgv.png',
        detalle: 'Alcohol / non Alcoholic.',
      },
      {
        id: "Hygienic",
        titulo: "Hygienic Products",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1683520624/Omni/Services/toalla-doblada-productos-cosmeticos-cepillos-dientes-sobre-fondo-naranja1_kpevye.jpg",
        detalle: "Hygienic products in transport solutions that combine quality and safety.",
      },
      {
        id: "GeneralGoods",
        titulo: "General Goods",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1683520407/Omni/Services/aaron-doucett-liOAS02GnfY-unsplash_ba61sa.jpg",
        detalle: "We give the best experience with logistics expertise and specialty services that take order fulfillment to the next level.",
      },
      {
        id: "RecycledE",
        titulo: "Recycled Electronics",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1683520406/Omni/Services/robin-glauser-zP7X_B86xOg-unsplash_ss1vz6.jpg",
        detalle: "We integrate our experienced touch solutions to assist you enhance product value chain, optimize logistics process and explore new markets.",
      },
      {
        id: "RecycledP",
        titulo: "Recycled Paper",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1683520407/Omni/Services/disposicion-objetos-sucios-arrojados_fr0x4z.jpg",
        detalle: "Our knowledgeable teams tailor solutions to deliver value and provide integrated and efficient transport. We work with many of the manufacturers in paper, print and recycling.",
      },
      {
        id: "HeavyEquipament",
        titulo: "Heavy Equipment",
        img: "https://res.cloudinary.com/omni-international-logistics/image/upload/v1683521061/Omni/Services/foto-linea-produccion-automoviles-soldadura-carrocerias-automoviles-planta-ensamblaje-automoviles-moderna-industria-automotriz6_odwvc5.jpg",
        detalle: "We deliver excellence that sets the bar to make our client’s  experience the best it can be.",
      }
    ],
    tech: 'Technology',
    t_subtitle: 'Logistics tech support and our development systems team help us operate efficiently and are the key to our logistics operation success.',
    t_one: 'Industry standard technology',
    t_two: 'Inventory management',
    t_three: '24/7 real time tracking',
    t_four: 'Track & Trace programs: Four Kites, Macro Point, among others',
    t_five: 'Warehouse management system',
    contact: 'Contact us',
    c_subtitle: 'Find out how we can help out and bring solutions to all your logistic needs.',
    c_btn: 'More Info',
    storage: {
      title_image: '<b>Warehouse & Distribution<b> <br> <p>We are committed to providing efficient logistical standards<p>',
      title: 'At Grobe, we are committed to providing efficient logistical standards that help organize, manage and carry out a safe environment for your products and service needs.',
      subtitle: 'We are equipped with the latest technology for order processing, warehouse management, packaging, product mixing and order fulfillment. - Crossdocking services - Managed Logistics - Storage facilities in Laredo, Texas 30,000 sqft available - Storage facilities in Fresno, California 30,000 sqft available - Less than truckload (LTL) - Expedited service - International logistical services in Monterrey, Mexico and Nuevo Laredo, TM.',
      button: 'Request Information',
      contact: 'Contact'
    },
    land: {
      title_image: '<b>Transportation and Supply Chain management<b> <br> <p>Custom solutions that are unique to your business<p>',
      title: 'Grobe’s variety of logistical services delivers custom solutions that are unique to your business and streamlines your provider network which includes to customers throughout the United States and Mexico.',
      subtitle: 'With our database of more than 2000 partner carriers internationally and domestically we can provide the capacity needed for your business needs. Including customer service 24/7. - Dedicated for contract-based customers - Temperature controlled - Company owned fleet - Flatbed - Less than truckload (LTL) Local or around 300 miles from our facilities in Laredo, TX or Fresno, CA -Expedited Services (team driver only) in all 48 states.',
      button: 'Request Information',
      contact: 'Contact'
    }
  },
  coverage: {
    seo: {
      title: 'Coverage',
      description: 'We Take your company further',
      keywords:
        'Logistics Services, Logistics Services in Mexico, Logistics Services in the United States',
    },
    title: 'We <b>Take</b> your <b>company further</b>',
    title_mobil: 'We <b>Take</b><br> your <b>company <br>further</b>',
    coverage: 'Coverage',
    c_details: 'Expand your company’s horizons!',
    mex: 'México Logistic points:',
    c_mex: [
      'Nuevo León',
      'Jalisco',
      'Coahuila',
      'Michoacán',
      'Zacatecas',
      'Puebla',
      'San Luis Potosí',
      'Estado de México',
      'Querétaro',
      'CDMX',
      'Guanajuato',
      'Hidalgo',
      'Chihuahua',
      'Sonora'
    ],
    usa: 'All USA',
    c_usa: [
    '(Exept Alaska and Hawaii)'
    ],
  },
  contact: {
    seo: {
      title: 'Schedule a call ¡Contact us!',
      description: 'Schedule a call ¡Contact us!',
      keywords:
        'Servicios Logísticos, Servicios Logísticos en México, Servicios Logísticos en Estados  Unidos',
    },
    title: 'Schedule a <b>call ¡Contact us!</b>',
    section_one: 'Do you need to quote a service? Looking to book one of our services? Send us your information and we’ll contact you briefly',
    so_details: 'Contact us to find out how we can help you formulate the solutions to your logistical problems.',
    ff_name: 'Name',
    ff_name_company: 'Name of the company',
    ff_email: 'E-mail',
    ff_phone: 'Telephone',
    ff_equip: 'Equipment Required',
    ff_source: 'Source',
    ff_destination: 'Destination',
    ff_comments: 'Comments',
    ff_privacy: 'I have read and agree the ',
    ff_privacy_link: 'privacy policy.',
    ff_btn: 'Submit',
    section_two: 'Interested in forming part of Grobe as a partner carrier?',
    st_details: 'We want to build a strong and permanent relationship with people who are passionate about the transportation and logistics industry, become an Grobe Carrier and succeed on your own terms.',
    sf_name: 'Name',
    sf_phone: 'Phone',
    sf_email: 'Email',
    sf_company: 'Company',
    sf_message: 'Message ',
    sf_privacy: 'I have read and agree the ',
    sf_privacy_link: 'privacy policy.',
    ff_btn: 'Submit',
    e_title: 'Email sended',
    e_subtitle: 'Thank U!',
    e_ok: 'Ok',
  },
  fotter: {
    details: 'Grobe International Logistics is more than just a logistics agency. We’re your complete solution for cross border commerce. Get to know where we come from and where we are headed.',
    contact: 'Contact',
    locations: 'Locations',
    copyright: '© 2022 All rights reserved',
    credits: 'Images Credit',
    privacy: 'Privacy Policy',
    direccion_one: 'Paseo Colón 3607 Colonia Jardín, Nuevo Laredo Tamaulipas C.P. 88260',
    direccion_two: '14203 Distribution Avenue, Laredo, TX 78045, Unites States',

  },
  credits: {
    title: 'Images Credits',
  },
  privacy: {
    title: 'Notice of Privacy',
    text: p_en,
  },
  cookies: {
    title: 'These cookies are<br>strictly needed for the website to work properly and can’t be deactivated.',
    description: `This website uses cookies to work properly, as well as to obtain statistical data used to provide a better service to our clients.<br>
    You can customize them in the section down below, once finished use the “Confirm cookies preferences”, You can also accept them all by using the “Accept all cookies” Button.
    For more information, please read our Privacy Policy.`,
    btn_one: 'Accept all Cookies',
    option_one: 'Strictly needed Cookies',
    message: '(Always active)',
    oo_description: `These cookies are strictly needed for the website to work properly and can’t be deactivated.
    This website uses cookies to work properly, as well as to obtain statistical data used to provide a better service to our clients.
    You can customize them in the section down below, once finished use the “Confirm cookies preferences”, You can also accept them all by using the “Accept all cookies” Button.
    For more information, please read our Privacy Policy.`,
    option_two: 'Statistical Cookies',
    od_description: 'These cookies are used to analyze statistical data and improve the quality of our services, as well as the user experience on the website.',
    btn_two: 'Confirm cookie’s preferences',
  },
  login: {
    title: 'Log in',
    email: 'Email',
    pass: 'password',
    close: 'Log out',
    required: 'This is a required field',
    format: 'Does not meet the required format',
  },
  editor: {
    title: 'Content editor',
    subtitle: 'Enter the new text for the selected field, this will replace the field that is currently registered.',
    original: 'Original text',
    new: 'New text',
    apply: 'Apply locally',
    load: 'Load originals',
    cancel: 'Cancel',
  },
  confirm: {
    title: 'Do you want to upload your changes?',
    subtitle: 'This operation is irreversible.',
    text: '',
    apply: 'Apply changes',
    cancel: 'Cancel',
  }
}
let en = !!enY ? enY : enX;
function ActualizarEN(data, nuevo) {
  if(!nuevo){
    return;
  }
  /*detailsEdit: {
    lang: '',
    page: '',
    element: '',
    subElement: '',
    isArray: '',
    numArray: '',
    objArray: '',
    textOriginal: '',
  }*/
  if(data.isArray){
    if(data.numArray >= 0) {
      if(data.objArray) {
        if(data.page && data.element){
          if(data.subElement){
            en[data.page][data.element][data.subElement][data.numArray][data.objArray] = nuevo;
          } else {
            en[data.page][data.element][data.numArray][data.objArray] = nuevo;
          }
        }
      } else {
        if(data.page && data.element){
          if(data.subElement){
            let original = en[data.page][data.element][data.subElement];
            original[data.numArray] = nuevo;
            en[data.page][data.element][data.subElement] = original;
          } else {
            let original = en[data.page][data.element];
            original[data.numArray] = nuevo;
            en[data.page][data.element][data.numArray] = original;
          }
        }
      }
      
    }
  }
  if(data.page && data.element && !data.isArray){
      if(data.subElement){
        en[data.page][data.element][data.subElement] = nuevo;
      } else {
        en[data.page][data.element] = nuevo;
      }
  }

  return;
}
export {
  ActualizarEN,
  en
}
