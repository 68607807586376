export const p_en = `GROBE INTERNATIONAL LOGISTICS (person to whom for the purposes of this privacy notice is
  appointed (¨The Responsible ¨) with the aim of respecting the rights and interests of each
  individual in relation to their privacy and personal data. In compliance with the principles
  and guidelines prescribed by the Mexican Federal Law of Protection of Personal Data in
  Possession of Individuals (hereinafter the law), is made available to each and every one of
  the natural persons, in any of its characters, either such as clients, employees, workers,
  candidates, suppliers, contractors or carriers, (hereinafter "owner" individually and "owners"
  collectively), of which the processing of personal data is necessary.
  This Privacy Notice, reason for which the following is stated:
  VI. Identity and address of the person responsible for the Processing of Personal Data:
  (The ̈responsible ̈) who indicates as address 14203 Distribution Ave, Laredo, TX
  78045, United States, through this notice, informs you that the personal data, which
  are duly cited in the following section, and that the active or potential "holder"
  provides us, will be treated strictly for the purposes indicated below, being the only
  responsible for the processing of said data, the Marketing area, located at the
  aforementioned address, with telephone number (867)3343939 and email
  accounting@grobeintllc.us
  VII. Personal data and purposes of the treatment of the same: The personal data of the
  "holders", which will be treated by the "responsible" consist of personal information,
  which may include: your name, email address, physical address, fiscal address ,
  telephone number, as well as financial and asset data, website address, among
  others. Strictly essential for the purposes indicated below. The ̈responsible ̈will
  collect your personal data and they will be treated only for the following purposes, as
  applicable, to each owner, according to their legal relationship with it:
  A)Evaluation as a supplier, contractor, carrier, client, employee, or potential worker.
  B) For the purposes of preparing and signing the contract(s) that, according to the
  corresponding legal relationship, are required where appropriate.
  C) Compliance with obligations arising from the corresponding legal relationship.
  D) Acquire, receive and provide the services and/or products required;
  E) Evaluate the quality of products and services.
  F) Extrajudicial collection proceedings in the case of delinquent clients or any other
  debtor.
  Make notices before judicial, administrative or labor authorities for the fulfillment of
  obligations derived from the particular relationship with each "holder". Personal data
  will be stored and protected under the strictest confidentiality and may not be given
  a use other than those mentioned above, unless there is a change in this Privacy
  Notice. Once the purpose of the processing of personal data is fulfilled, they will be
  blocked for the sole purpose of determining possible responsibilities in relation to
  their processing, until their legal or contractual prescription period. During this
  period, the personal data may not be processed and after this, it will be canceled in
  the corresponding database.
  Collection of Personal Data: The "responsible" follows all the principles established
  by the "Law" such as legality, quality, consent, information, purpose, loyalty,
  proportionality and responsibility. The personal data will be collected directly from
  the owner in a personal way with printed means and formats or through other
  optical, sound, visual means, or by any other lawful technology such as our website
  or online services, among others. The information we receive from our website when
  leaving and providing your data is recorded in our databases.
  Cookies are used on this website. (A cookie is a small text file that a website or email
  can save in your browser and store on your hard drive. In addition, we may obtain
  owner information from other sources permitted by law, such as telephone
  directories, references of companies or individuals, public databases of any entity or
  public or private agency, among others.
  Options and means to achieve the use or disclosure of data: ¨The Responsible¨ has
  the necessary and sufficient administrative, technical and physical security measures
  to protect your personal data against damage, loss, alteration, destruction, use,
  access and/or or unauthorized treatment. Personal data is safeguarded in databases
  and computer equipment that have the necessary security to prevent information
  leaks, physical and logical access controls, passwords, anti-intrusion protection
  systems (IPS, Firewall), protection tools antivirus and web filtering, which, among
  others, are some of the tools used to maintain the security of the data in the
  information systems of the "responsible".
  The computer security tools mentioned in the previous paragraph are supported by
  an internal information security policy that explains to employees the security
  considerations that they must take into account when using a computerized system
  and is constantly reinforced. For this reason, it is made known to you that you may
  request the limitation of the use or disclosure of your personal data, subject of this
  Privacy Notice, through a letter sent to the Marketing area of the "responsible" at
  the address indicated in section number I of this Notice.
  Means to exercise the rights of access, rectification, cancellation and opposition, in
  accordance with the provisions of the Law: The "holders" by their own right through
  their proxy or legal representative, duly accredited and identified, may exercise their
  rights of access, rectification, cancellation and/or opposition, through a written
  request, addressed to the Marketing area, containing the following:
  Name of the ¨holder¨, accompanying the documents that prove their identity. In case
  of being legal representatives or representatives, attach the document or documents
  that prove it, including simple power of attorney, signed before two witnesses.
  Address or email to receive notifications.
  The clear and precise description of the personal data and the right that it intends to
  exercise over it.
  The elements that facilitate the location of the data whose right is intended to be
  exercised, such as the date on which the data was obtained. For such purposes, once
  your request has been received, the "responsible" through the corresponding area
  will reply on the admissibility or inadmissibility of the request, within a period not
  exceeding twenty days, communicating in writing at the address indicated by the
  "owner" , or in the email indicating for this purpose. This procedure does not
  generate any type of charge or compensation for any concept for the "holder". As a
  result of the foregoing, the "responsible" is not obliged to cancel the personal data in
  the case of any of the assumptions established in the "Law".
  Transfers of data that are made: Personal data will not be disclosed or shared with
  national or foreign third parties, except in the case of employment references, or
  data transfers for the purpose of recommendation and evaluation of acquisition,
  reception and provision of services, to the In light of the foregoing, the affiliated
  companies of the "responsible" and that are part of GROBE INTERNATIONAL
  LOGISTICS, which require personal data for the aforementioned purposes, are not
  considered third parties.
  VII. Revocation, the "Holders" may revoke their consent for the processing of
  personal data at any time, and must only send a written request to the Marketing
  area at the address of the "responsible" for such purposes, who within a maximum
  period of eight business days will inform the owner. For this purpose, the
  "responsible" may communicate to the "owner" about the origin of his request by
  any means, the resolution that falls on his request for revocation.
  Modifications to the Privacy Notice: we reserve the right to change this privacy
  notice at any time. These modifications will be available in any of the following
  means:
  a)Visible advertisements in our offices
  b)On our website and/or
  c)Via registered email that has been provided to us
  We will not be responsible if you do not receive notice of changes to the privacy
  notice. If there is a problem with your email or internet data account.
  This notice may be consulted as many times as required on the electronic page:
  www.grobeintllc.us
  Update: January 2022`;

export const p_es = `GROBE INTERNATIONAL LOGISTICS (persona a quien para los efectos de este aviso de
  privacidad se le nombra (¨El Responsable ̈) con el objetivo de respetar los derechos e
  intereses de cada individuo en relación con su intimidad y sus datos personales. En
  cumplimiento a los principios y directrices prescritos por la Ley Federal de Protección en
  Mexico de datos Personales en Posesión de Particulares (en lo sucesivo a la ley), se pone a
  disposición de todos y cada una de las personas físicas, en cualquiera de sus caracteres, ya
  sea como clientes, empleados, trabajadores, candidatos, proveedores, contratistas o
  transportistas, (en adelante ¨titular¨ en lo individual y ¨titulares¨ en lo colectivo), de las
  cuales sea necesario el tratamiento de datos personales.
  El presente Aviso de Privacidad, motivo por el cual se manifiesta lo siguiente:
  I. Identidad y domicilio del responsable del Tratamiento de Datos Personales: (El
  ̈responsable ̈) quien señala como domicilio en 14203 Distribution Ave, Laredo, TX
  78045, Estados Unidos, por medio del presente Aviso, hace de su conocimiento que
  los datos personales, los cuales son debidamente citados en apartado siguiente. y
  que nos proporciona el ̈titular ̈activo o potencial, serán tratados estrictamente para
  los fines que más adelante se señalan, siendo el único responsable del tratamiento
  de dichos datos, el área de Mercadotecnia, ubicada en el domicilio referido, con
  numero de telefono (867)3343939 y correo electrónico accounting@grobeintllc.us
  II. Datos Personales y finalidades del tratamiento de los mismos: Los datos personales
  de los ¨titulares¨, que serán tratados por el ¨responsable¨ consisten en información
  personal, la cual puede comprender: su nombre, dirección de correo electrónico,
  domicilio fisico, domicilio fiscal, numero de telefono, asi como datos financieros y
  patrimoniales, direccion de pagina web, entre otros. Estrictamente indispensables
  para los fines que más adelante se señalan. El ̈responsable ̈recabará sus datos
  personales y serán tratados únicamente para los siguientes fines, conforme le sean
  aplicables, a cada titular, según su relación jurídica con el mismo:
  A) Evaluación como proveedor, contratista, transportista, cliente, empleado, o
  trabajador potencial.
  B) Para efectos de la elaboración y celebración de (los) contrato(s) que, acorde a la
  relación jurídica correspondiente, en su caso se requieran.
  C) El cumplimiento de obligaciones que deriven de la relación jurídica,
  correspondiente.
  D) Adquirir, recibir y proveer los servicios y/o productos requeridos; e) Evaluar la
  calidad de los productos y servicios.
  F) Diligencias de cobranza extrajudicial en el caso de clientes morosos o cualquier
  otro deudor.
  G) Realizar avisos ante autoridades judiciales, administrativas o del trabajo para el
  cumplimiento de obligaciones derivadas de la relación particular que se tenga con
  cada ¨titular¨. Los datos personales serán guardados y resguardados bajo la más
  estricta confidencialidad y no se les podrá dar un uso distinto a los antes
  mencionados, salvo que medie un cambio en este Aviso de Privacidad. Una vez que
  se cumpla la finalidad del tratamiento de datos personales, éstos serán bloqueados
  con el único propósito de determinar posibles responsabilidades en relación con su
  tratamiento, hasta el plazo de prescripción legal o contractual de estas. Durante
  dicho periodo, los datos personales no podrán ser objeto de tratamiento y
  transcurrido éste, se procederá a su cancelación en la base de datos que
  corresponda.
  III. Recoleccion de los Datos Personales: el ̈responsable ̈sigue todos los principios que
  marca la ¨Ley¨ como la licitud,calidad, consentimiento, información, finalidad,
  lealtad, proporcionalidad y responsabilidad. Los datos personales serán recabados
  directamente del titular de forma personal con medios y formatos impresos o a
  través de otros medios ópticos, sonoros, visuales, o por cualquier otra tecnología
  lícita como nuestra página de internet o servicios en línea, entre otras. La
  información que recibimos de nuestra página de internet al dejar y proporcionar sus
  datos, es registrada en nuestras bases de datos.
  En este sitio web se utilizan ¨cookies¨. (Una cookie es un pequeño archivo de texto
  que un sitio web o un correo electrónico pueden salvar en su navegador y
  almacenarlo en su disco duro. Además, podremos obtener información del titular de
  otras fuentes permitidas por la Ley, tales como directorios telefónicos, referencias de
  empresas o particulares, bases de datos públicas de cualquier entidad o dependencia
  pública o privada, entre otras.
  IV. Opciones y medios para lograr el uso o divulgación de los datos: ¨El Responsable¨
  cuenta con las medidas de seguridad administrativas, técnicas y físicas necesarias y
  suficientes para proteger sus datos personales contra daño, pérdida, alteración,
  destrucción, uso, acceso y/o tratamiento no autorizado. Los datos personales son
  salvaguardados en bases de datos y equipos de cómputo que cuentan con la
  seguridad necesaria para prevenir fugas de información, controles de acceso fisico y
  logico, contraseñas, passwords, sistemas de protección anti intrusos (IPS, Firewall),
  herramientas de protección antivirus y filtrado web, las cuales, son entre otras,
  algunas de las herramientas utilizadas para mantener la seguridad de los datos en los
  sistemas de información del ¨responsable¨.
  Las herramientas de seguridad informática mencionadas en el párrafo anterior están
  apoyadas por una política interna de seguridad de la información que explica a los
  empleados las consideraciones de seguridad que deben tomar en cuenta al utilizar
  un sistema informático y es reforzada constantemente. Por ello, se hace de su
  conocimiento que usted podrá solicitar la limitación del uso o divulgación de sus
  datos personales, materia de este Aviso de Privacidad, a través de escrito enviado al
  área de Mercadotecnia del ¨responsable¨ al domicilio indicado en el apartado
  número I de este Aviso.
  V. Medios para ejercer los derechos de acceso, rectificación, cancelación y oposición,
  de conformidad con lo dispuesto en la Ley: Los ¨titulares¨ por su propio derecho a
  través de su apoderado o representante legal, debidamente acreditado e
  identificado, podrá ejercer sus derechos de acceso, rectificación, cancelación y/u
  oposición, a través de solicitud por escrito, dirigido al área de Mercadotecnia, que
  contenga lo siguiente:
  1. Nombre del ¨titular¨, acompañando los documentos que acrediten su
  identidad. En caso de ser apoderados legales o representantes, adjuntar el o
  los documentos que así lo acrediten, incluyendo carta poder simple, firmada
  ante dos testigos.
  2. Domicilio o correo electrónico para recibir notificaciones.
  3. La descripción clara y precisa del dato personal y el derecho que pretenda
  ejercer sobre aquél.
  4. Los elementos que faciliten la localización del dato cuyo derecho se pretenda
  ejercer, tal como la fecha en la cual se obtuvo el dato. Para tales efectos, una
  vez recibida su solicitud, el ¨responsable¨ mediante el área correspondiente
  dará contestación sobre la procedencia o improcedencia de la solicitud, en un
  plazo no mayor a veinte días, comunicando por escrito en el domicilio
  señalado por el ¨titular¨, o bien en el correo electrónico señalando para tal
  efecto. Este procedimiento no genera ningún tipo de cargo o compensación
  por ningún concepto para el ¨titular¨. En consecuencia de lo anterior el
  ¨responsable¨ no está obligado a cancelar los datos personales cuando se
  trate de alguno de los supuestos establecidos en ¨Ley¨.
  VI. Transferencias de datos que se efectúen: Los datos personales no serán divulgados ni
  compartidos con terceros nacionales o extranjeros, salvo cuando se trate de referencias
  laborales, o transferencias de datos con fines de recomendación y evaluación de adquisición,
  recepción y provisión de servicios, a la luz de lo anterior no se considera como tercero a las
  empresas filiales del ¨responsable¨ y que formen parte de GROBE INTERNATIONAL LOGISTICS,
  mismas que requieran tener los datos personales para los fines antes mencionados.
  VII. Revocación, los ̈Titulares ̈ podrán revocar su consentimiento para el tratamiento de
  datos personales en cualquier momento, debiendo únicamente para tales efectos enviar una
  solicitud por escrito al área de Mercadotecnia en el domicilio del ¨responsable¨, quien en un
  plazo máximo de ocho días hábiles informará al ¨titular¨. Para tal efecto el ¨responsable¨
  podrá comunicar al ¨titular¨ sobre la procedencia de su solicitud por cualquier vía, la
  resolución que recaiga sobre su solicitud de revocación.
  VIII. Modificaciones al Aviso de Privacidad, nos reservamos el derecho de cambiar este aviso
  de privacidad en cualquier momento. Estas modificaciones estarán disponibles en cualquiera
  de los siguientes medios:
  A) Anuncios visibles en nuestras oficinas
  B) En nuestra página de internet y/o
  C) Via correo electronico registrado y que nos haya sido proporcionado
  No seremos responsables si usted no recibe notificaciones de cambio en el aviso de
  privacidad. Si existiese algún problema con su cuenta de correo electrónico o de datos por
  internet.
  El presente aviso podrá consultarlo las veces que así lo requiera en la página electrónica:
  www.grobeintllc.us
  Actualización: Enero 2022
  `;
