let firebase = require("firebase/app");

// Add additional services that you want to use
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");
require("firebase/functions");

firebase.default.initializeApp({
  apiKey: process.env.VUE_APP_FB_APIKEY,
  authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FB_PREJECTID,
  //databaseURL: process.env.,
  storageBucket: process.env.VUE_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGINGSENDID,
  appId: process.env.VUE_APP_FB_APPID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENTID
});
//firebase.default.analytics();
firebase.default.firestore().settings({
  timestampsInSnapshots: true
})

const auth = firebase.default.auth();
const db = firebase.default.firestore();
const storage = firebase.default.storage();
//const functions = firebase.functions()

auth.setPersistence(firebase.default.auth.Auth.Persistence.SESSION);
export {
  firebase,
  auth,
  db,
  storage,
  //functions
}