import Vue from 'vue';
import Vuex from 'vuex';
import login from "./login";
import i18n from "./i18n";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    editor: false,
    detailsEdit: {
      lang: '',
      page: '',
      element: '',
      subElement: '',
      isArray: '',
      numArray: '',
      objArray: '',
      textOriginal: '',
    },
  },
  getters: {
    getEdit(state) {
      return state.editor;
    },
    getEditData(state) {
      return state.detailsEdit;
    }
  },
  mutations: {
    changeEdit(state) {
      state.editor = !state.editor;
      if(!state.editor){
        state.detailsEdit = {
          lang: '',
          page: '',
          element: '',
          subElement: '',
          isArray: '',
          numArray: '',
          objArray: '',
          textOriginal: '',
        }
      }
    },
    chargeDetailsEdit(state, data) {
      state.detailsEdit = data;
    },
    resetDetailsEdit(state) {
      state.detailsEdit = {
        lang: '',
        page: '',
        element: '',
        subElement: '',
        isArray: '',
        numArray: '',
        objArray: '',
        textOriginal: '',
      }
    }
  },
  actions: {
    async chargeEditData({commit}, data) {
      await commit('chargeDetailsEdit', data);
      await commit('changeEdit');
    }
  },
  modules: {
    login,
    i18n,
  }
})
