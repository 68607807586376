import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { auth } from './firebase';
import vuetify from "./plugins/vuetify";
import '@/assets/css/fonts.css';

// store.dispatch('i18n/es');
// store.dispatch('i18n/en');

import i18n from "./plugins/i18n";
import "./plugins/ganalitycs";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueCookies from "vue-cookies";
import VueMeta from "vue-meta";
import Edit from "@/components/helpers/Edicion.js";

Vue.use(VueCookies);
Vue.$cookies.config("7d");

// import style (>= Swiper 6.x)
import 'swiper/css/swiper.css'
Vue.prototype.editar = Edit;
Vue.use(VueAwesomeSwiper);
Vue.use(VueMeta);

Vue.config.productionTip = false;
auth.onAuthStateChanged(user => {
  if (user) {
    // store.dispatch('login/iniciarSesion', user.uid);
  }
  else {
    store.dispatch('login/cerrarSesion');
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
