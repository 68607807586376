<template>
  <v-app>
    <v-main class="pa-0">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  components: {},
  metaInfo: {
    titleTemplate: "%s | Grobe",
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions("i18n", ["es", "en"]),
    async cargarIdioma() {
      if(this.$t('version') === 'version') {
        localStorage.clear();
        location.reload()
        console.log('test')
      }
      // this.es();
      // this.en();
    }
  },
  created() {
    this.cargarIdioma();
  },
};
</script>
